import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'

import { images } from '../../helpers/images';
import { AuthContext } from '../../auth/AuthContext';

export const NavTopLegal = () => {

     const { user: { lang }, dispatch } = useContext(AuthContext);

    return (
        <section id="NavTop-legal">
            <div className="container d-flex align-items-center justify-content-between flex-column flex-lg-row">
                <Link to="/">
                   {lang === 'CA' &&
                        <img src="/logo_test_rado.png" width="170" height="35" className="card-img my-2" alt="Logo"/>
                    }

                    {lang === 'ES' &&
                        <img src="/logo_test rado_CAST.png" width="170" height="35" className="card-img my-2" alt="Logo"/>
                    }
                </Link>
                
            </div>
        </section>
    )
}
