import React from "react";
import { Accordion } from "react-bootstrap";

export const FaqsEs = () => {
    return (
        <>
            <h5 className="text-center mb-4">
                FAQ’S
            </h5>

            <Accordion alwaysOpen className="mb-5">
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <strong>
                            ¿Qué debo hacer antes de solicitar el Test Radón?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Hay que leer atentamente las condiciones de cada servicio para saber qué solicitud necesitaré hacer según mis necesidades.
                        </p>
                        <p>
                            Hay que disponer de ciertos datos del edificio donde quiero medir:
                        </p>
                        <p>
                            •	Dirección y propietario
                        </p>
                        <p>
                            •	Superficie útil y altura de cada una de las plantas.
                        </p>
                        <p>
                            •	La dirección donde se quiere recibir los detectores.
                        </p>
                        <p>
                            •	Recomendable: año de construcción e información de si ha habido obras o reformas previas.
                        </p>
                        <p>
                            •	En el caso del Test Profesional, se tiene que identificar la persona que define las zonas de muestreo e identificar, en caso de que sea una medición posterior a una intervención, identificar las soluciones constructivas implantadas para hacer frente el radón según exigencias CTE DB HS6.
                        </p>
                        <p>
                            Para poder iniciar el proceso de solicitud, es necesario registrarse en Test Radón para poder realizar cualquiera de las acciones dentro de su perfil de usuario.
                        </p>
                        <p>
                            Se recomienda, evitar mediciones de corta duración o inferiores a 1 año, durante el periodo estival (junio-septiembre) y/o en todo caso, volver a repetir el ensayo durante el periodo de invierno.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <strong>
                            ¿Cómo puedo saber cuantos detectores pedir para hacer el Test Radón?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            A medida que vayas llenando los datos del edificio, el programa te calculará el número de detectores necesarios. El mínimo será siempre de 2 detectores.
                        </p>
                        <p>
                            Finalizada la fase de determinar el número de detectores, el solicitante, puede escoger añadir detectores de forma adicional. Estos detectores adicionales tienen un coste de 30 euros/detector.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <strong>
                            ¿Qué incluye el precio de mi solicitud?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El servicio de test radón se ofrece a precios para el público a partir de 115 euros (con iva) y para los conveniados a partir de 100 euros (con iva). Este precio se ha calculado con el servicio mínimo que incluye:
                        </p>
                        <p>
                            •	Medición larga (mínimo 3 meses)
                        </p>
                        <p>
                            •	2 detectores
                        </p>

                        <p>
                            •	Envío de los detectores
                        </p>
                        <p>
                            •	Acceso a la plataforma de gestión del Test Radón
                        </p>
                        <p>
                            •	Información y atención al usuario en la gestión y el proceso del Test Radón
                        </p>

                        <p>
                            •	Resultado del Test
                        </p>

                        <p>
                            •	Acceso a la bolsa de técnicos de los colegios profesionales de la arquitectura técnica para que le puedan asesorar en la fase de muestreo y/o análisis de resultados para la mitigación de radón.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        <strong>
                            ¿Qué NO incluye el precio de mi solicitud?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            •	Devolución de los detectores al centro de recepción (puede consultar la información de devolución de los detectores).
                        </p>
                        <p>
                            •	Gastos generados por el desistimiento fuera del plazo y las condiciones de la compra del servicio.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                    <Accordion.Header>
                        <strong>
                            ¿Cómo se realiza el pago del Test Radón?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El pago de la solicitud de Test Radón, únicamente se puede realizar con tarjeta bancaria.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                    <Accordion.Header>
                        <strong>
                            ¿En cuánto tiempo recibiré los detectores?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El período estimado es de 2-5 días desde que se realiza el pago.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                    <Accordion.Header>
                        <strong>
                            ¿Cuál es el procedimiento seguir una vez reciba los detectores?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Hay que entrar en el perfil de usuario y:
                        </p>
                        <p>
                            •	Descargar las instrucciones de uso y colocación.
                        </p>
                        <p>
                            •	Hay que indicar de forma expresa que se han recibido los detectores, en número exacto al cual consta en su solicitud. Si hay cualquier incidencia en la recepción de los detectores y no se informa de forma expresa, el Cateb no se responsabiliza de la no emisión de los resultados del Test.
                        </p>
                        <p>
                            •	Finalizada la medición y antes de enviar los detectores, se deben rellenar todos los datos pendientes de la solicitud y marcar la opción “Enviar en el laboratorio”.
                        </p>
                        <p>
                            NOTA: Recuerde que una vez se saquen los detectores del plástico que los contiene, habrá empezado la medición.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                    <Accordion.Header>
                        <strong>
                            ¿Dónde debo devolver los detectores una vez finalice la medición?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Finalizado el periodo de medición, el cliente es el encargado de gestionar el retorno de los detectores en nuestro centro de recepción. Puede hacerlo presencialmente y/o gestionar la devolución con el transportista de su elección.
                        </p>
                        <p>
                            La devolución se tiene que hacer en nuestro centro de recepción situado a la siguiente dirección postal:
                        </p>
                        <p>
                            <strong>
                                C/Buenos Aires, 21 planta baja
                            </strong>
                        </p>
                        <p>
                            <strong>
                                08029 Barcelona
                            </strong>
                        </p>
                        <p>
                            El horario habitual del Cateb es de lunes a jueves de 9:00h a 17:00h y viernes de 9:00h. a 14:30h.
                        </p>
                        <p>
                            Todos los detectores recibidos en la solicitud deben devolverse en un mismo sobre o caja. En este sobre o caja, se deben indicar los siguientes datos: ID de la solicitud y los datos del solicitante (nombre y apellidos/empresa).
                        </p>
                        <p>
                            Nunca envolver los detectores directamente en una bolsa de plástico precintada.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="10">
                    <Accordion.Header>
                        <strong>
                            ¿Cómo obtendré los resultados?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Podrá hacer el seguimiento del estado de su solicitud hasta descargar el resultado del test una vez finalizada el proceso de análisis del laboratorio.
                        </p>
                        <p>
                            El resultado del análisis estará disponible en PDF dentro de la solicitud del cliente a la plataforma de gestión de <a href="https://www.testrado.es" className="color-primary" target="_blank" rel="noopener noreferrer">www.testrado.es</a>
                        </p>
                        <p>
                            El resultado obtenido será la media de concentración de radón (Bq/m<sup>3</sup>) durante el periodo de exposición de los detectores.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="11">
                    <Accordion.Header>
                        <strong>
                            ¿Qué puedo hacer si el resultado de la medida es elevado?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Hay que tener en cuenta que los niveles de radón varían en función del día, hábitos de los usuarios, estación del año, por ello se recomienda realizar en lo posible, mediciones en diferentes periodos del año para obtener un resultado del nivel medio anual.
                        </p>
                        <p>
                            Un nivel de referencia (300 Bq/m<sup>3</sup>) no se trata de un límite no permitido, sino de un valor que se recomienda no superar, con el fin de facilitar la supervisión y el control de la exposición de la población en su conjunto. No existe un nivel de radón por debajo del cual no haya un riesgo asociado de contraer cáncer de pulmón.
                        </p>
                        <p>
                            En caso de obtener resultados que superen este nivel de referencia, se recomienda contactar con un profesional (arquitecto técnico) para que lo aconseje sobre las medidas de mitigación se pueden llevar a cabo para rebajar el nivel medio de concentración de radón.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>


        </>
    )
};
